import { useDispatch, useSelector } from 'react-redux';
// material
import { Box, Card, Typography, Modal, Button } from '@material-ui/core';
import useLocales from 'hooks/useLocales';
import { RootState } from 'redux/store';
import { closeAssesmentLimitDialog } from 'redux/slices/dialogs';

export default function AssessmentLimitModal() {
  const { t } = useLocales();
  const dispatch = useDispatch();
  const { open, message }: any = useSelector(
    (state: RootState) => state.dialogs.assesmentErrorMessage
  );
  const closeAsessmentDialog = () => {
    dispatch(closeAssesmentLimitDialog());
  };
  return (
    <Modal open={open} onClose={closeAsessmentDialog}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%'
        }}
      >
        <Card sx={{ width: 700, textAlign: 'center', p: '8px 64px' }}>
          <Typography variant="h2">Warning Message</Typography>
          <Typography variant="body1">{t(message)}.</Typography>
          <Button
            sx={{ fontSize: 20, m: 2 }}
            size="large"
            variant="contained"
            onClick={closeAsessmentDialog}
          >
            {t('Close')}
          </Button>
        </Card>
      </Box>
    </Modal>
  );
}
