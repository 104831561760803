import {
    getDataByUrl,
    postDataByUrl,
    updateDataByUrl,
    deleteDataByUrl,
    putDataByUrl
} from 'requests';



export const getCustomers = (params: any) => getDataByUrl('/customers/', params);

export const getCustomerById = (id: any) => getDataByUrl(`/customers/${id}/`);

export const getCustomerNotesDate = (id: any) => getDataByUrl(`/customers/${id}/notes/`);

export const getCustomerFilesData = (id: any) => getDataByUrl(`/customers/${id}/files/`);

export const CreateCustomer = (customerFelds: any) =>
    postDataByUrl(`/customers/`, customerFelds);

export const UpdateCustomer = (id: any, customerFelds: any) =>
    putDataByUrl(`/customers/${id}/`, customerFelds);

export const UpdateCustomerData = (id: any, data: any) =>
    updateDataByUrl(`/customers/${id}/`, data);

export const DeliteCustomer = (id: any) => deleteDataByUrl(`/customers/${id}/`);
