import { Location } from 'history';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';

// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Grid, ListItemIcon, ListItemText } from '@material-ui/core';
// theme
import { useDispatch, useSelector } from 'react-redux';
import { PATH_DASHBOARD } from 'routes/paths';
import useLocales from '../../../hooks/useLocales';
import { setDashboardModal } from '../../../redux/slices/dashboard';
import { RootState } from '../../../redux/store';

// ----------------------------------------------------------------------

const GridStyle = styled(Grid)(({ theme }: any) => ({
  '&.isActiveSub': {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    '& .subIcon:before': {
      transform: 'scale(2)',
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const stylePop = {
  backgroundColor: 'violet',
  zIndex: 1351,
  left: '160px',
  background: '#FFFFFF',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
  borderRadius: '10px',
  padding: '30px'
};

// ----------------------------------------------------------------------

type NavItemProps = {
  level: number;
  title: string;
  href?: string;
  info?: ReactNode;
  icon?: ReactNode;
  open?: boolean;
  children?: ReactNode;
  iconDisabled?: ReactNode;
};

export default function SidebarItem({
  level,
  title,
  href,
  info,
  icon,
  iconDisabled,
  open = false,
  children
}: NavItemProps) {
  const history = useHistory();

  const [show, setShow] = useState(false);
  const [isMatchActive, setIsMatchActive] = useState(false);
  const isSubItem = level > 0;
  const { t } = useLocales();
  const dashboardStore = useSelector((state: RootState) => state.dashboard);
  const { modalState } = dashboardStore;
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const handleShow = (title: any) => {
    if (title) {
      dispatch(setDashboardModal(title));
    }
    setShow(!show);
  };

  useEffect(() => {
    if (title !== modalState) {
      setShow(false);
    }
  }, [title, modalState]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent): void => {
      setTimeout(() => {
        if (
          show &&
          ref.current &&
          !ref.current.contains(e.target as HTMLElement)
        ) {
          setShow(!show);
        }
      }, 100);
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [show]);

  const { pathname } = history.location;
  const splitPathname = pathname.split('/');
  const splitHref = href?.split('/');
  const rightMenu =
    splitPathname[2]?.toLowerCase() ===
    (splitHref ? splitHref[2] : '')?.toLowerCase();

  const rightPath = href ? pathname.includes(href) : false;

  if (children) {
    return (
      <>
        {show && modalState === title ? (
          <Grid style={stylePop} sx={{ position: 'fixed' }} ref={ref}>
            {children}
          </Grid>
        ) : null}
        <Grid
          alignItems="center"
          sx={{
            marginBottom: '10px',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            position: 'relative'
          }}
        >
          <ListItemIcon
            onClick={() => {
              handleShow(title);
            }}
            sx={{ marginLeft: '22px', marginBottom: '5px' }}
          >
            {(modalState === title && show) ||
            rightMenu ||
            (href === '/dashboard/jobs/' &&
              pathname === PATH_DASHBOARD.jobPosts.applicantsOverview)
              ? icon
              : iconDisabled}
          </ListItemIcon>

          <ListItemText
            onClick={() => handleShow(title)}
            disableTypography
            primary={t(title)}
          />
        </Grid>

        {info && info}
      </>
    );
  }

  const getIcon = () => {
    let img;
    if (
      ((modalState === title && show) || rightMenu || rightPath) &&
      !isSubItem &&
      !(
        pathname === '/dashboard/candidates/applicants-overview/' &&
        title === 'My profile'
      )
    ) {
      img = icon;
    } else {
      img = iconDisabled;
    }
    return img;
  };

  return (
    <GridStyle
      onClick={() => {
        !isSubItem && handleShow(title);
      }}
      alignItems="center"
      sx={{
        ...(!isSubItem
          ? {
              marginBottom: '10px',
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              textDecoration: 'none',
              color: '#252B42'
            }
          : {
              marginBottom: '10px',
              display: 'flex',
              flexDirection: 'row',
              textAlign: 'start',
              textDecoration: 'none',
              '&:before': {
                width: 4,
                height: 4,
                content: "''",
                display: 'block',
                borderRadius: '50%',
                backgroundColor: '#000',
                transition: 'transform'
              }
            })
      }}
      to={href}
      exact={open}
      disablegutters="true"
      component={RouterLink}
      activeClassName={
        (isMatchActive && isSubItem) || (rightPath && isSubItem)
          ? 'isActiveSub'
          : 'isActiveRoot'
      }
      isActive={(match: { url: string }, location: Location) => {
        if (!match) {
          return false;
        }

        const { url } = match;
        const { pathname } = location;
        const isMatch = url === pathname;

        if (!isSubItem) {
          setIsMatchActive(!!(url.length && pathname.includes(url)));
          return url.length && pathname.includes(url);
        }
        setIsMatchActive(isMatch);
        return isMatch;
      }}
    >
      <ListItemIcon
        sx={
          !isSubItem
            ? { marginLeft: '22px', marginBottom: '5px' }
            : { margin: '0 px 0 30px' }
        }
      >
        {getIcon()}
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={t(title)}
        style={{ fontSize: '16px', fontFamily: 'Rubik' }}
      />
      {info && info}
    </GridStyle>
  );
}
