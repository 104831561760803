import { ReactNode } from 'react';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { Box, Drawer, List } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// components
import { useSelector } from 'react-redux';
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
//
import {
  candidateItems,
  companyIsInactiveItems,
  employeeItems,
  recruiterItems,
  adminItems,
  hiringManagerItems,
  reviewerItems,
  recruiterPlusItems
} from './SidebarConfig';
import SidebarItem from './SidebarItem';
import useAuth from '../../../hooks/useAuth';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { RootState } from '../../../redux/store';

const DRAWER_WIDTH = 155;

type TNavItem = {
  icon?: ReactNode;
  iconDisabled?: ReactNode;
  info?: ReactNode;
  href: string;
  title: string;
  openState?: boolean;
  items?: TNavItem[];
};

type ReduceChildParams = {
  array: ReactNode[];
  item: TNavItem;
  pathname: string;
  level: number;
};

function reduceChild({ array, item, pathname, level }: ReduceChildParams) {
  const key = item.href + level;
  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false
    });
    return [
      ...array,
      <SidebarItem
        key={key}
        level={level}
        icon={item.icon}
        iconDisabled={item.iconDisabled}
        info={item.info}
        href={item.href}
        title={item.title}
        open={Boolean(match)}
      >
        {renderSidebarItems({
          pathname,
          level: level + 1,
          items: item.items
        })}
      </SidebarItem>
    ];
  }
  return [
    ...array,
    <SidebarItem
      key={key}
      level={level}
      href={item.href}
      iconDisabled={item.iconDisabled}
      icon={item.icon}
      info={item.info}
      title={item.title}
    />
  ];
}

type RenderSidebarItemsParams = {
  items: TNavItem[];
  pathname: string;
  level?: number;
};

function renderSidebarItems({
  items,
  pathname,
  level = 0
}: RenderSidebarItemsParams) {
  return (
    <List>
      {items.reduce<ReactNode[]>(
        (array, item) => reduceChild({ array, item, pathname, level }),
        []
      )}
    </List>
  );
}

type NavBarProps = {
  isOpenSidebar?: boolean;
  onCloseSidebar?: VoidFunction;
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar
}: NavBarProps) {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const {
    user: { role, id, candidate_id },
    isAdmin,
    isEmployee,
    isCandidate,
    isRecruiter,
    isRecruiterPlus,
    isHiringManager,
    isReviewer
  } = useAuth();

  const company: any = useSelector(
    (state: RootState) => state.generalRecruiter.company
  );
  const adminCompanyInactive = isAdmin && !company.active;

  const getMenuLinks = () => {
    switch (true) {
      case adminCompanyInactive:
        return companyIsInactiveItems(t);

      case isAdmin:
        return adminItems(t, company.is_agency);

      case isCandidate:
        return candidateItems(t);

      case isEmployee:
        return employeeItems(t);

      case isHiringManager:
        return hiringManagerItems(t);

      case isReviewer:
        return reviewerItems(t);

      case isRecruiterPlus:
        return recruiterPlusItems(t, company.is_agency);

      case isRecruiter:
        return recruiterItems(t, company.is_agency);

      default:
        return recruiterItems(t, company.is_agency);
    }
  };

  const MenuLinks = getMenuLinks();

  // useEffect(() => {
  //   if (isOpenSidebar && onCloseSidebar) {
  //     onCloseSidebar();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathname]);

  const getLogoUrl = () => {
    switch (role) {
      case 'recruiter':
        return company.active
          ? PATH_DASHBOARD.account.showcase
          : PATH_DASHBOARD.company.settings;
      case 'admin':
        return company.active
          ? PATH_DASHBOARD.account.showcase
          : PATH_DASHBOARD.company.settings;
      case 'candidate':
        return PATH_DASHBOARD.candidates.getCandidateProfileUrl('me');
      case 'employee':
        return PATH_DASHBOARD.candidates.getCandidateProfileUrl(candidate_id);
      default:
        return PATH_DASHBOARD.account.showcase;
    }
  };
  const renderContent = (
    <Scrollbar>
      <Box sx={{ px: 1.5, py: 3 }}>
        <RouterLink to={getLogoUrl}>
          <Logo
            width="131px"
            height="87px"
            url={company.logo || '/static/img/favicon/icon-192x192.png'}
            style={{ objectFit: 'contain' }}
          />
        </RouterLink>
      </Box>
      {MenuLinks.map((list: any) => (
        <List disablePadding key={list.subheader} sx={{ cursor: 'pointer' }}>
          {renderSidebarItems({
            items: list.items,
            pathname
          })}
        </List>
      ))}
    </Scrollbar>
  );

  return (
    <Drawer
      open={isOpenSidebar}
      onClose={onCloseSidebar}
      PaperProps={{
        sx: { width: DRAWER_WIDTH }
      }}
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH
        }
      }}
      variant="persistent"
      anchor="left"
    >
      {renderContent}
    </Drawer>
  );
}
