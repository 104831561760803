import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  applyJobPost,
  getJobPost,
  postNewJobPost,
  updateJobPost,
  uploadJobFile
} from 'requests/vacancy';
import { ID } from 'requests';
import { getInitialValuesByType } from '../../helpers/formInitialValues';
import {
  getAllJobPosts,
  getCompetency,
  getRemoteJobs
} from '../../requests/fixtures';

const initialState = {
  jobData: getInitialValuesByType('job-post'),
  jobPost: null,
  loading: true,
  allJobPosts: null,
  newJobPost: null,
  updatedJobPost: null,
  competencies: null,
  remote_job: [],
  dataFromJobFile: null
};

export const fetchJob = createAsyncThunk('job/fetchJob', async (id: ID) =>
  getJobPost(id)
);

export const postJob = createAsyncThunk('job/postJob', async (data: any) =>
  postNewJobPost(data)
);

export const fetchAllJobPosts = createAsyncThunk(
  'job/fetchAllJobPosts',
  async () => getAllJobPosts()
);

export const fetchJobPost = createAsyncThunk(
  'job/fetchJobPost',
  async (data: { vacancy: string | number }) => applyJobPost(data)
);

export const fetchRemoteJobs = createAsyncThunk(
  'job/fetchRemoteJobs',
  async () => getRemoteJobs()
);

export const fetchCompetency = createAsyncThunk(
  'job/fetchCompetency',
  async () => getCompetency()
);

export const patchJobPost = createAsyncThunk(
  'job/patchJobPost',
  async (params: { id: ID; data: any }) => {
    const { id, data } = params;
    return updateJobPost(id, data);
  }
);

export const uploadJobAI = createAsyncThunk('job/saveJob', async (data: any) =>
  uploadJobFile(data)
);

export const resetDataFromJobFile = createAction('job/resetDataFromJobFile');

export const resetNewJobPost = createAction('job/resetNewJobPost');

const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(patchJobPost.fulfilled, (state, action) => {
        state.updatedJobPost = action.payload;
      })
      .addCase(fetchRemoteJobs.fulfilled, (state, action) => {
        state.remote_job = action.payload;
      })
      .addCase(fetchCompetency.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCompetency.fulfilled, (state, action) => {
        state.competencies = action.payload;
      })
      .addCase(postJob.fulfilled, (state, action) => {
        state.newJobPost = action.payload;
      })
      .addCase(resetNewJobPost, (state) => {
        state.newJobPost = null;
      })
      .addCase(fetchAllJobPosts.fulfilled, (state, action) => {
        state.allJobPosts = action.payload.results;
      })
      .addCase(fetchJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchJob.fulfilled, (state, action) => {
        state.loading = false;
        state.jobData = action.payload;
      })
      .addCase(fetchJobPost.fulfilled, (state, action) => {
        state.jobPost = action.payload;
      })

      .addCase(uploadJobAI.fulfilled, (state, action) => {
        state.dataFromJobFile = action.payload;
      })
      .addCase(resetDataFromJobFile, (state) => {
        state.dataFromJobFile = null;
      });
  }
});

export default jobSlice.reducer;
