import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { login, logout, register } from '../redux/slices/authJwt';

type LoginParams = {
  email: string;
  password: string;
};

type RegisterParams = {
  email: string;
  firstName: string;
  lastName: string;
  asRecruiter: boolean;
  role?: string | null;
  invitation?: string | null;
  password?: string;
};

export default function useAuth() {
  const dispatch = useDispatch();
  const { user, isLoading, isAuthenticated } = useSelector(
    (state: RootState) => state.authJwt
  );
  const { role } = user;

  const isAdmin = role === 'admin';
  const isEmployee = role === 'employee';
  const isCandidate = role === 'candidate';
  const isRecruiter = role === 'recruiter';
  const isRecruiterPlus = role === 'recruiter_plus';
  const isHiringManager = role === 'hiring_manager';
  const isReviewer = role === 'reviewer';
  const recruiterPermission = user?.recruiter_permission;
  const userId = user.id;
  const userName = `${user.first_name} ${user.last_name}`;

  return {
    method: 'jwt',
    user,
    isLoading,
    isAuthenticated,

    login: ({ email, password }: LoginParams) =>
      dispatch(
        login({
          email,
          password
        })
      ),

    register: ({
      email,
      firstName,
      lastName,
      asRecruiter,
      role,
      invitation,
      password
    }: RegisterParams) =>
      dispatch(
        register(
          {
            email,
            firstName,
            lastName,
            asRecruiter,
            role,
            password
          },
          invitation
        )
      ),

    logout: () => dispatch(logout()),
    isAdmin,
    isEmployee,
    isCandidate,
    isRecruiter,
    isRecruiterPlus,
    isHiringManager,
    isReviewer,
    recruiterPermission,
    userId,
    userName
  };
}
