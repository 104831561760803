import { Modal } from '@material-ui/core';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from 'redux/store';
import { PATH_DASHBOARD } from 'routes/paths';
import useAuth from 'hooks/useAuth';
import CultureProfileBanner from './CultureProfileBanner';

type BannersProviderProps = {
  children: ReactNode;
};

const BannersProvider = ({ children }: BannersProviderProps) => {
  const [openModal, setOpenModal] = useState(false);

  // NOTE: If you need some state to check if it needed to show some banner, make it here

  const count: number | null = useSelector(
    (state: RootState) => state.generalRecruiter.cultureProfilesCount
  );
  const { active }: any = useSelector(
    (state: RootState) => state.generalRecruiter.company
  );

  const { user } = useAuth();
  const { role, candidate_id } = user;
  const isCandidate = role === 'candidate' && candidate_id;

  const history = useHistory();
  const handleModalClose = () => {
    setOpenModal(false);
    history.push(PATH_DASHBOARD.culture_profile.getCultureProfileUrl('new'));
  };

  const needToUpdateProfile = useMemo(
    () => active && !isCandidate && count === 0,
    [active, isCandidate, count]
  );

  useEffect(() => {
    if (needToUpdateProfile) setOpenModal(true);
  }, [needToUpdateProfile]);

  return (
    <>
      <Modal open={openModal}>
        <>
        <CultureProfileBanner onClose={handleModalClose} />
        </>
      </Modal>
      {children}
    </>
  );
};

export default BannersProvider;
