import { getDataByUrl, postDataByUrl, deleteDataByUrl, ID } from 'requests';

export const getInvitations = (params?: any) =>
  getDataByUrl(`/invitations/`, params);

export const getAcceptRecruiterInvitation = (key: ID) =>
  getDataByUrl(`/invitations/accept-invite/${key}/`);

export const createInvitation = (data: any) =>
  postDataByUrl(`/invitations/`, data);

export const resendInvitation = (id: ID) =>
  postDataByUrl(`/invitations/${id}/resend_invitation_email/`, {});

export const deleteInvitation = (id: ID) =>
  deleteDataByUrl(`/invitations/${id}/`);
