import { ReactNode, useState } from 'react';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import useAuth from 'hooks/useAuth';
import { useLocation } from 'react-router';
import DashboardSidebar from './sidebar/DashboardSidebar';
import DashboardBannersProvider from './banners/BannersProvider';
import DashboardRequestChecker from './requests/DashboardRequestChecker';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')(() => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  background: 'linear-gradient(107.72deg, #F5F7FF -2.8%, #D7D8EB 110.1%)'
}));

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  // background: 'linear-gradient(107.72deg, #F5F7FF -2.8%, #D7D8EB 110.1%)',
  minHeight: '100%',
  paddingTop: `${APP_BAR_MOBILE + 24}px !important`,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

type DashboardLayoutProps = {
  children?: ReactNode;
};

export default function DashboardLayout({ children }: DashboardLayoutProps) {
  const [open, setOpen] = useState(true);
  const auth = useAuth();
  const { pathname } = useLocation();
  // <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
  const fromCustomer = pathname.includes('/dashboard/customers/');

  return (
    <RootStyle>
      {auth.isAuthenticated && (
        <>
          <DashboardSidebar
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
          />
        </>
      )}
      <MainStyle>
        <DashboardRequestChecker fromCustomer={fromCustomer}>
          <DashboardBannersProvider>
            {auth.isAuthenticated}
            {children}
          </DashboardBannersProvider>
        </DashboardRequestChecker>
      </MainStyle>
    </RootStyle>
  );
}
