import {
  getDataByUrl,
  postDataByUrl,
  updateDataByUrl,
  deleteDataByUrl,
  ID
} from 'requests';

export const getCmsBaseData = () => getDataByUrl('/cms/');
export const getCmsGalleryData = () => getDataByUrl('/cms/gallery/');
export const getCmsOffersData = () => getDataByUrl(`/cms/offer/`);

export const createCmsGalleryData = (data: any) =>
  postDataByUrl(`/cms/gallery/`, data);
export const createCmsOffersData = (data: any) =>
  postDataByUrl(`/cms/offer/`, data);

export const updateCmsBaseData = (id: ID, data: any) =>
  updateDataByUrl(`/cms/${id}/`, data);
export const updateCmsGalleryData = (id: ID, data: any) =>
  updateDataByUrl(`/cms/gallery/${id}/`, data);
export const updateCmsOffersData = (id: ID, data: any) =>
  updateDataByUrl(`/cms/offer/${id}/`, data);

export const deleteCmsGalleryData = (id: ID) =>
  deleteDataByUrl(`/cms/gallery/${id}/`);
export const deleteCmsOffersData = (id: ID) =>
  deleteDataByUrl(`/cms/offer/${id}/`);

export const getCmsMediaData = () => getDataByUrl('/cms/social-media/');
export const updateCmsMediaData = (id: ID, data: any) =>
  updateDataByUrl(`/cms/social-media/${id}/`, data);
export const saveCmsMediaData = (data: any) =>
  postDataByUrl(`/cms/social-media/`, data);
export const deleteCmsMediaData = (id: any) =>
  deleteDataByUrl(`/cms/social-media/${id}/`);
