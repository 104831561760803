import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { rootReducer } from './rootReducer';

export const history = createBrowserHistory();
const store = configureStore({
  reducer: rootReducer(history),
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  }).concat(routerMiddleware(history))
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

export { store, dispatch };
